import React, { useEffect, useState } from "react";
import DatasetFilter from "../../components/DatasetFilter";
import { navigate } from "gatsby";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { IDataset, IDatasetsResponse, IFilters } from "../../types/datasets";
import { parseDatasetTag } from "../../utils";
import useURLQueryParameter from "../../hooks/queryParamHook";
import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";
import BannerComponent from "../../components/Banner";
import { IN_HOUSE_DATASETS_STATUS_VALUE } from "../../constants";
import PageLayout from "../../components/PageLayout";
import PageContent from "../../components/PageContent";
import PageBoundary from "../../components/PageBoundary";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { XCircleIcon } from "@heroicons/react/20/solid";
import DatasetList from "../../components/DatasetList";
import LoadingStatus from "../../components/LoadingStatus";
import SelectDropdown from "../../components/SelectDropdown";
import { SelectChangeEvent } from "@mui/material";

const defaultFilters = (): IFilters => ({
  vendor: {},
  serviceCategory: {},
  serviceSubcategory: {},
  applicableSector: {},
  applicableGeography: {},
  applicableAssetClass: {},
  status: {},
});
const setInitialFilters = (filterQueryParam: string) => {
  const filters = defaultFilters();

  if (!filterQueryParam) return filters;
  else {
    const existingFilters: [{ name: keyof IDataset; values: string[] }] =
      JSON.parse(decodeURIComponent(filterQueryParam));
    for (const category of existingFilters) {
      for (const val of category.values) {
        filters[category.name][val] = true;
      }
    }
    return filters;
  }
};

enum SortOptions {
  Featured = "Featured",
  Alphabetical = "A-Z",
  ReverseAlphabetical = "Z-A",
}

const sortOptions = [
  { name: "Featured", value: SortOptions.Featured },
  { name: "A-Z", value: SortOptions.Alphabetical },
  { name: "Z-A", value: SortOptions.ReverseAlphabetical },
];

const DataCatalog: React.FC = () => {
  const { accessToken, clearIdentity } = useAuthContext();

  const [datasets, setDatasets] = useState<IDataset[]>([]);
  const [translations, setTranslations] = useState<{ [key: string]: string }>(
    {},
  );
  const [datasetHeader, setDatasetHeader] = useState<string>("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [searchQueryParam, updateSearchQueryParam] =
    useURLQueryParameter("searchTerm");
  const [filterQueryParam, updateFilterQueryParam] =
    useURLQueryParameter("filters");
  const [searchTerm, setSearchTerm] = useState<string>(
    (searchQueryParam || "") as string,
  );
  const [filters, setFilters] = useState<IFilters>(
    setInitialFilters(filterQueryParam as string),
  );

  const [sortSelection, setSortSelection] = useState<SortOptions>(
    SortOptions.Featured,
  );

  const { data, isLoading, error, fetchData } = useNorthstar<IDatasetsResponse>(
    `/api/datasets-catalog/datasets`,
    accessToken,
    {
      method: "get",
      params: searchQueryParam ? { searchTerm: searchQueryParam } : {},
    },
  );

  useEffect(() => {
    let ignore = true;
    if (error?.status === 401 && !ignore && clearIdentity) clearIdentity();
    return () => {
      ignore = false;
    };
  }, [error?.status]);

  useEffect(() => {
    // Trigger data fetching with the new search term.
    // TODO: not sure if this is correct
    if (searchQueryParam !== undefined) {
      fetchData(`/api/datasets-catalog/datasets`, false, {
        method: "get",
        params: searchQueryParam ? { searchTerm: searchQueryParam } : {},
      });
    }
  }, [searchQueryParam]);

  useEffect(() => {
    if (data?.data) {
      setDatasets(data?.data?.datasets);
      setTranslations(data?.translations?.translation);
    }
  }, [data]);

  useEffect(() => {
    if (!searchQueryParam && !filterQueryParam)
      setDatasetHeader(
        `All Datasets ${isLoading ? "" : `(${filteredDatasets.length})`}`,
      );
    else
      setDatasetHeader(
        `Search Results ${searchQueryParam ? "for " + searchQueryParam : ""} ${
          isLoading ? "" : `(${datasetsToRender().length})`
        }`,
      );
  });

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setSortSelection(event.target.value as SortOptions);
  };

  const sorted = (datasets: IDataset[]) => {
    switch (sortSelection) {
      case SortOptions.Featured:
        let featured: IDataset[] = [];
        const nonfeatured: IDataset[] = [];

        datasets.forEach((ds) => {
          if (ds.isFeatured) featured.push(ds);
          else nonfeatured.push(ds);
        });

        featured.sort((a, b) => {
          if (a.lastModifiedDate < b.lastModifiedDate) return 1;
          return -1;
        });

        nonfeatured.sort((a, b) => {
          if (a.lastModifiedDate < b.lastModifiedDate) return 1;
          return -1;
        });

        featured = featured.concat(nonfeatured);

        return featured;

      case SortOptions.Alphabetical:
        datasets.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 1;
        });
        return datasets;
      case SortOptions.ReverseAlphabetical:
        datasets.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
          return -1;
        });
        return datasets;
    }
  };

  const datasetsToRender = () => {
    const filteredDataset: Set<IDataset> = new Set();
    const sortedDatasets = sorted(datasets);

    for (const dataset of sortedDatasets) {
      for (const filterCategory in filters) {
        const currentFilterValues = Object.keys(filters[filterCategory]);

        if (currentFilterValues.length > 0) {
          // Check if any filters currently exist in a specific filter category
          if (!dataset[filterCategory as keyof IDataset]) continue;

          if (filterCategory === "status") {
            if (IN_HOUSE_DATASETS_STATUS_VALUE.has(dataset.status)) {
              if (currentFilterValues.includes("Yes"))
                filteredDataset.add(dataset);
            } else if (currentFilterValues.includes("No"))
              filteredDataset.add(dataset);
          } else if (filterCategory === "vendor") {
            if (currentFilterValues.includes(dataset[filterCategory].name))
              filteredDataset.add(dataset);
          } else if (
            typeof dataset[filterCategory as keyof IDataset] === "object"
          ) {
            for (const value of dataset[
              filterCategory as keyof IDataset
            ] as string[]) {
              for (const filterValue of currentFilterValues)
                if (parseDatasetTag(value, translations) === filterValue)
                  filteredDataset.add(dataset);
            }
          } else if (
            currentFilterValues.includes(
              parseDatasetTag(
                dataset[filterCategory as keyof IDataset] as string[],
                translations,
              ),
            )
          )
            filteredDataset.add(dataset);
        }
      }
    }
    if (Array.from(filteredDataset).length) return Array.from(filteredDataset);
    else return sortedDatasets;
  };

  const filteredDatasets = datasetsToRender();

  const updateFilterQueryParams = (newFilters: IFilters) => {
    const filters = [];
    for (const category in newFilters) {
      if (Object.values(newFilters[category]).length > 0)
        filters.push({
          name: category,
          values: Object.keys(newFilters[category]).map((option) => option),
        });
    }

    updateFilterQueryParam(
      filters.length ? encodeURIComponent(JSON.stringify(filters)) : "",
    );
  };

  const filterTags = () => {
    const output = [];
    for (const category in filters) {
      for (const option of Object.keys(filters[category]).sort()) {
        const optionLabel =
          option === "Yes" || option === "No" ? "In-House - " + option : option;
        output.push({ category, option, optionLabel });
      }
    }
    return output;
  };

  const removeFilterTag = (category: string, value: string) => {
    const newFilters = { ...filters };
    delete newFilters[category][value];
    updateFilterQueryParams(newFilters);
    setFilters(newFilters);
  };

  const returnToDefaultView = () => {
    updateSearchQueryParam("");
    setSearchTerm("");
    updateFilterQueryParam("");
    navigate("/datasets");
    setFilters(defaultFilters());
    // fetchData("/api/data-catalog/datasets");
  };

  const handleSearchBarChange = (value: string) => {
    setSearchTerm(value);
    if (!value && searchQueryParam) {
      fetchData("/api/datasets-catalog/datasets");
      updateSearchQueryParam("");
    }
  };

  let datasetListTemplate = <LoadingStatus loadingType="datasets_loading" />;
  if (isLoading) {
    datasetListTemplate = <LoadingStatus loadingType="datasets_loading" />;
  } else if (error) {
    datasetListTemplate = <LoadingStatus loadingType="datasets_error" />;
  } else if (filteredDatasets.length === 0) {
    datasetListTemplate = <LoadingStatus loadingType="datasets_empty" />;
  } else {
    datasetListTemplate = (
      <DatasetList datasets={filteredDatasets} translations={translations} />
    );
  }

  const tempSEOData = {
    title: "BAM Dataset Catalogue",
    description: "Browse BAM's dataset catalogue to help support your research",
  };

  const tempBannerData = {
    title1: "Browse BAM's dataset catalogue to help support your research",
    description: `The Datasets page provides a searchable collection of commercial data that we have encountered. This search tool was custom-built for our core investors to identify opportunities to integrate new data into products and operations.`,
  };

  return (
    <PageLayout seo={tempSEOData}>
      <PageContent className="flex flex-col">
        <div className="flex flex-col">
          <BannerComponent
            title1={tempBannerData.title1}
            description={tempBannerData.description}
          />
        </div>
        <PageBoundary className="w-full">
          <div className="mt-2">
            <div className="flex py-1 items-center space-x-1">
              <InformationCircleIcon className="h-4 text-blue-500" />
              <div className="text-xs sm:text-sm text-gray-700">
                The records on this page are strictly for data discovery and do
                not provide access to the data.
              </div>
            </div>
            <div className="mt-2">
              <div className="flex flex-col md:flex-row w-full justify-center mt-2 xs:mt-3 sm:mt-4">
                <div className="w-full md:w-2/3 lg:w-3/4 xl:w-[55rem] md:pr-3 lg:pr-4">
                  <TextField
                    id="dataset-search"
                    placeholder="Search Datasets"
                    label=""
                    autoComplete="off"
                    variant="outlined"
                    onChange={(e) => {
                      handleSearchBarChange(e.target.value);
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? updateSearchQueryParam(searchTerm)
                        : null
                    }
                    value={searchTerm}
                    fullWidth
                    sx={{
                      ".MuiOutlinedInput-root": {
                        borderRadius: "0px",
                        bgcolor: "white",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: searchTerm && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear search"
                            onClick={() => {
                              returnToDefaultView();
                            }}
                            edge="end"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="w-full md:w-1/3 lg:w-1/4 xl:w-[19rem] mt-3 xs:mt-4 md:mt-0">
                  <SelectDropdown
                    label="Sort by"
                    value={sortSelection}
                    onChange={handleSortChange}
                    options={sortOptions}
                  />
                </div>
              </div>
            </div>
            <div className="my-3 font-semibold text-gray-800">
              {datasetHeader}
            </div>
            <div className="flex flex-wrap my-2 items-center">
              <button
                type="button"
                className="inline-flex items-center mr-4 my-1 px-4 py-2 border border-blue-900 text-blue-900 font-medium text-sm hover:bg-blue-900 hover:text-white"
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              >
                Filters
              </button>
              {filterTags().length > 0 && (
                <>
                  <button
                    type="button"
                    className="inline-flex items-center mr-4 my-1 px-4 py-2 border border-blue-900 text-blue-900 font-medium text-sm hover:bg-blue-900 hover:text-white"
                    onClick={() => {
                      setFilters(defaultFilters());
                      updateFilterQueryParam("");
                    }}
                  >
                    Clear All
                  </button>
                  <div className="mr-4">
                    <ul className="flex flex-wrap">
                      {filterTags().map((tag, index) => {
                        const { category, option, optionLabel } = tag;
                        return (
                          <li
                            key={`${index}-${option}`}
                            className="flex items-center text-sm my-1 mr-4 px-4 py-2 border border-gray-200 bg-gray-200 text-gray-800"
                          >
                            <div className="mr-1">{optionLabel}</div>
                            <XCircleIcon
                              className="h-5 cursor-pointer text-gray-400 hover:text-gray-500"
                              onClick={() => {
                                removeFilterTag(category, option);
                              }}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              )}
            </div>
            {datasetListTemplate}
            <DatasetFilter
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              datasets={datasets}
              filters={filters}
              setFilters={setFilters}
              translations={translations}
              updateFilterQueryParam={updateFilterQueryParam}
            />
          </div>
        </PageBoundary>
      </PageContent>
    </PageLayout>
  );
};

export default DataCatalog;
