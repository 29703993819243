import { HomeIcon, StarIcon } from "@heroicons/react/24/solid";
import React from "react";
import { IN_HOUSE_DATASETS_STATUS_VALUE } from "../../constants";
import Tag from "../Tag";
import { Link } from "gatsby";

interface TileProps {
  id: string;
  title: string;
  description?: string;
  tag: string;
  vendorName: string;
  status: string;
  isFeatured: boolean;
}

const DatasetTile: React.FC<TileProps> = ({
  id,
  title,
  description,
  tag,
  vendorName,
  status,
  isFeatured,
}) => {
  return (
    <Link to={`/datasets/dataset?id=${id}`}>
      <div className="flex flex-col w-full h-full overflow-hidden px-4 py-3 bg-white hover:shadow-lg">
        <div className="flex mt-0.5 items-center justify-between">
          <div className="flex-1 text-blue-500 font-semibold">{vendorName}</div>
          <div className="flex-shrink-0 inline-block">
            {isFeatured && (
              <StarIcon title="Featured" className="h-6 text-blue-400" />
            )}
          </div>
        </div>
        <div className="mt-3 line-clamp-2 text-lg xs:text-xl font-semibold text-gray-900">
          {title}
        </div>
        <div className="min-h-[4.725rem] my-2 xs:my-3 lg:my-4 line-clamp-3 text-gray-600">
          {description}
        </div>
        <div className="grow h-0"></div>
        <div className="h-8 mb-1.5 flex items-center">
          {tag && <Tag id={id} name={tag} />}
        </div>
      </div>
    </Link>
  );
};

export default DatasetTile;
